import "./header.css";

export default function Header() {
  return (
    <div></div>
    // // <div className="header">
    // //   <h1> PROOF </h1>
    //   {/* <div className="headerTitles"> */}
    //     {/* <span className="headerTitleSm">React & Node</span>
    //     <span className="headerTitleLg">BLOG</span> */}
    //   {/* </div> */}
    // // </div>
  );
}
