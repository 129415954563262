import Topbar from "./components/topbar/Topbar";
import Homepage from "./pages/homepage/Homepage";
import Read from "./pages/read/Read";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Settings from "./pages/settings/Settings";
import Single from "./pages/single/Single";
import Write from "./pages/write/Write";
import About from "./pages/new-about/new_about";
import About2 from "./pages/new-about/about_p";
import Submit from "./pages/submit/Submit";
import StandFor from "./pages/stand-for/StandFor";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  const currentUser = true;
  return (
    <Router>
      {/* <Topbar /> */}
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route path="/posts">
          <Homepage />
        </Route>
        <Route path="/write">{currentUser ? <Write /> : <Login />}</Route>
        <Route path="/about">
          <About2/>
        </Route>

        <Route path="/featured">
          <Read/>
        </Route>

        <Route path="/submit">
          <Submit/>
        </Route>


        <Route path="/stand-for">
          <StandFor/>
        </Route>

      </Switch>
    </Router>
  );
}

export default App;
