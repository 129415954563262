export default function StandFor(){
    return (
        <div>
        <center><h1 class="bigtitle" style={{fontSize: "50px"}}>WHAT PROOF STANDS FOR</h1></center>

            <div>
            The theme of this magazine is reflection. Not only self-reflection or the act of reflecting on one's past
            but the reflection we see of ourselves in one another, and the proof in that reflection, in the creation
            and management of this very magazine, in the stories, the poems, and the art it sends back out to the 
            world, that there is a different day, a light ahead. That there is "where we were", "wher we are", and 
            "where we will be". That our trials do not simply absorb into a pointless timeless, neverending tragedy, 
            but we bounce back, instead, give back, instead, shine back, instead return, instea. Return, time and 
            again.

            </div>

            <div>
                YOU being here reflects that.
            </div>

            <div>
                YOU are PROOF.
            </div>
            
            <button>
                BACK
            </button>

        </div>
    )
}