import "./write.css";
// import { S3Client, AbortMultipartUploadCommand } from "@aws-sdk/client-s3";
// import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3"; // ES Modules import
// const { S3Client, PutObjectCommand } = require("@aws-sdk/client-s3"); // CommonJS import
const aws = require('aws-sdk'); //for s3 saving
// Initialize the Amazon Cognito credentials provider
aws.config.region = 'us-east-1'; // Region
aws.config.credentials = new aws.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:97d914e8-2ad0-4c37-b464-0dabb001a94b',
});
const s3 = new aws.S3();


// const client = new S3Client(config);
// const command = new PutObjectCommand(input);
// "s3://prooflit/entries/"
// export const bucketParams = {
//   Bucket: "BUCKET_NAME",
//   Key: "KEY",
// };


export default function Write() {
"s3://prooflit/entries/"

  function submitEssay(){
    console.log("Here")
  }

  function getStoryInfo(event){
    const title = event.target.getElementsByClassName("writeFormGroup")[0].getElementsByClassName("writeInput")[0].value;
    const story = event.target.getElementsByClassName("writeFormGroup")[1].getElementsByClassName("writeInput")[0].value;
    return {title: title, story: story};
  }
  function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  
  

  function generate_key(){
    const todays = new Date();
    const yyyy = todays.getFullYear();
    let mm = todays.getMonth() + 1; // Months start at 0!
    let dd = todays.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const today = mm + '-' + dd + '-' + yyyy;
    let uuidv = uuidv4()
    let f = `entries/${today}/Angelo/${uuidv}-entry.json`
    return f

  }

  function handleSubmit(event) {

    let info = getStoryInfo(event);
    let key = generate_key()
    const promise = s3.putObject({
      Bucket: "prooflit",
      Key: key,
      Body: JSON.stringify(info),
      // ContentType: contentType
  }).promise()
  
    event.preventDefault()
    console.log(event)
  }

  return (
    <div className="write">
      <img
        className="writeImg"
        src="https://images.pexels.com/photos/6685428/pexels-photo-6685428.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
        alt=""
      />
      <form className="writeForm" onSubmit={handleSubmit}>
        <div className="writeFormGroup">
          <label htmlFor="fileInput">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <input id="fileInput" type="file" style={{ display: "none" }} />
          <input
            className="writeInput"
            placeholder="Title"
            type="text"
            autoFocus={true}
          />
        </div>
        <div className="writeFormGroup">
          <textarea
            className="writeInput writeText"
            placeholder="Tell your story..."
            type="text"
            autoFocus={true}
          />
        </div>
        <button className="writeSubmit" type="submit" onClick={submitEssay}>
          Publish
        </button>
      </form>
    </div>
  );
}
