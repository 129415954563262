import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from "./Card.jsx"


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function AutoGrid({card_info}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
      {card_info.map(function(card, index){
    return (
        <Grid item xs>
        <Card title={card.title} text={card.text} img_path={card.img}/>
        </Grid>)
    })}
      </Grid>
    </Box>
  );
}
