import { useLocation } from "react-router";
import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import "./homepage.css";



export default function Homepage() {
  const location = useLocation();
  const start_page_dist = 30
  console.log(location);
  return (
    <>
    <div class="bg">
    <center><h1 class="bigtitle" style={{fontSize: "200px"}}>PROOF</h1></center>
    </div>

      <Header />
      <div className="container">

        <div>
          <Link className="link" to="/featured">
          <h2 className="translated" data-text="Featured" style={{"top": start_page_dist.toString() + "%"}}>Featured</h2>
          </Link>
        </div>

        <div>
        <Link className="link" to="/submit">
        <h2 className="inverted" data-text="Submit" style={{"top": (start_page_dist + 15).toString() + "%"}}>Submit</h2>
        </Link>
        </div>

        <div>
        <Link className="link" to="/about">
        <h2 className="inverted" data-text="About" style={{"top": (start_page_dist + 30).toString() + "%"}}>About</h2>
        </Link>

        </div>

        </div>


    </>
  );
}
