import { useLocation } from "react-router";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import React, { useState, CSSProperties, useEffect } from 'react';
import BeatLoader from "react-spinners/BeatLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import Card from "./Card.jsx"
import Grid from "./Grid.jsx"
import "./read.css";

const aws = require('aws-sdk'); //for s3 saving
// Initialize the Amazon Cognito credentials provider
aws.config.region = 'us-east-1'; // Region
aws.config.credentials = new aws.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:97d914e8-2ad0-4c37-b464-0dabb001a94b',
});
const s3 = new aws.S3();

const BUCKET="prooflit";

function get_s3_object(key, callback){
    const promise = s3.getObject({Bucket: BUCKET, Key: key}).promise().then(callback);
    
}


export default function Read() {
    let proseList = []
    const [articles, setArticle] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    let [color, setColor] = useState("#00000");
    let articleList = {}
    const override: CSSProperties = {
        borderColor: "red",
        borderWidth: "1px",
        paddingLeft: "10%"
      };

    let orderList = ""
    let unixToDateMap = {};
    function read_article(result){
        let article = result.Body.toString('utf-8');
        let date = result.$response.request.params.Key.substring(6,16);
        console.log(date)
        if (Object.keys(articleList).length == proseList.length - 1){
            articleList[Date.parse(date)] = article
            unixToDateMap[Date.parse(date)] = date
            var keys = Object.keys(articleList);
            keys.sort().reverse();
            let finalList = []
            for (var i=0; i<keys.length; i++) {
                let img = 'https://prooflit.s3.amazonaws.com/prose/' + unixToDateMap[keys[i]] + '/image.jpg'
                let title = "LOGIC FOR TITLE NEXT"
                // let title = 'https://prooflit.s3.amazonaws.com/prose/' + unixToDateMap[keys[i]] + '/title.txt'
                finalList.push({text: articleList[keys[i]], title: title, img: img});
            }
            
            setArticle(finalList)
        }
        else {
            unixToDateMap[Date.parse(date)] = date
            articleList[Date.parse(date)] = article
        }
        setIsLoading(false)
    }
    function read_data(result){
        let datesToLoad = result.Body.toString('utf-8').split(/\r?\n/);; // Use the encoding necessary
        proseList = datesToLoad
        for (let date in datesToLoad) {
            get_article(datesToLoad[date])
        }
    }
    
      useEffect(() => {
        const objectData  = get_s3_object("prose/submission_order_prose.txt", read_data)
    
      },[]);
    
    function get_article(prefix){
        get_s3_object("prose/" + prefix + "/entry.txt", read_article)
    }
    const location = useLocation();
    console.log(location);
    return (
        <div className="sweet-loading">
        {{isLoading} ?
        <div className="centered">
        <ScaleLoader
        color="blue"
        loading={isLoading}
        cssOverride={override}
        size={1000}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      </div>:

        <div>
            <h1>{articles}</h1>
        </div>
    }
    <Grid card_info={articles}/>
    {/* {articles.map(function(article, index){
    return <div>
        <Card title={"Dummy Title"} text={article}/>
        <h1 key={ index }>{article}</h1>
        <div> BREAK HERE </div>
        </div>;
    })} */}


        </div>

    );
}
