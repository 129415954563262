


export default function Submit(){
    return (
        <div>

        <div class="bg">
        <center><h1 class="bigtitle" style={{fontSize: "200px"}}>SUBMIT</h1></center>
        </div>

        <div>
            We accept submissions via email [submissions@prooflit.org] and Submittable [button below]. Please read the
            guidelines before sending in your art or writing.
        </div>


        <div>
            <div style={{fontSize: "30px"}}> WRITING:</div>
            <li> 1-3 poems per submission; 10 pages max.</li>
            <li> 1 story per submission OR 3 flash fiction pieces; 5,000 words max.</li>
        </div>

        <div>
            <div style={{fontSize: "30px"}}> VISUAL ART:</div>
            <li> 1-3 drawings [scanned]</li>
            <li> 1-3 photographs</li>
        </div>


        <div>
            If you have art other than writing, drawings, or photography that you'd like to share,
            please email us at submissions@prooflit.org.
        </div>

        </div>

          );
    
}