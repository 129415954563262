export default function About2(){
    return(
        <div>
        <center><h1 class="bigtitle" style={{fontSize: "50px"}}>ABOUT</h1></center>


            <div>
                PROOF is a non-profit youth literary magazine desgined to uplift, empower, and promote young artists [14-22] 
                in recovery. The team consists of adult creative writers and students in recovery. We receive most of our  <b>submissions</b> 
                 from recovery high schools across the country, but also accept general entries from students at standard high schools
                or members of youth addiction and recovery programs.
            </div>

            <div>
                Developing young writers and artists is central to PROOF's mission, so our staff editors provide feedback on every
                submission we receive.
            </div>
        </div>
    )
}